$(document).ready(function() {
    
    if($('body').hasClass('page-id-7')){
        
        let controller = new ScrollMagic.Controller();
        new ScrollMagic.Scene({
            duration: 1000,
            offset: 0
        })
            .setPin('.block_foruconsult-block_homeheader_animated')
            .on("progress", function(event) {
                let rotation = 36 * event.progress;
                let opacity = 1 - (event.progress * 2);
                let scale = 1 + (event.progress / 3);
                let translateX = $(window).width() <= 992 ? -65 * event.progress : -130 * event.progress;
                let translateXHeader = $(window).width() <= 992 ? -65 * event.progress + 45 : -130 * event.progress;
        
                $('.block_foruconsult-block_homeheader .anim-main svg').css({
                    transform: `translate3d(${translateX}%, 0, 0) rotate(${rotation}deg) scale(${scale})`
                });
        
                $('.block_foruconsult-block_homeheader .anim-main h1').css({
                    transform: `translate3d(${translateXHeader}%, 0, 0)`,
                    opacity: opacity * -1
                });
        
                $('.block_foruconsult-block_homeheader .anim-hide').css({
                    opacity: opacity
                });
            })
            .addTo(controller);
        
        new ScrollMagic.Scene({
            duration: 1000,
            offset: 1000
        })
            .setPin('.block_foruconsult-block_homeheader_animated')
            .on("progress", function(event) {
                $('.dna-point').each(function(index) {
                    let pointOpacity = Math.min(1, Math.max(0, (event.progress - (index * (1 / $('.dna-point').length))) * $('.dna-point').length));
                    $(this).css({
                        opacity: pointOpacity
                    });
                });
            })
            .addTo(controller);   
    }
});
