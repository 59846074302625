// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function($, window, document, undefined) {

  "use strict";

  // Create the defaults once
  var pluginName = "svwSCalendar",
	defaults = {
	  months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
	  days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'], //string of days starting from sunday
	  fixedStartDay: true, // Week begin always by monday
	  yearTargetID: false,
	  eventTargetID: false,
	  events: [] //List of event
	};

  // The actual plugin constructor
  function Plugin(element, options) {
	this.element = element;
	this.settings = $.extend({}, defaults, options);
	this._defaults = defaults;
	this._name = pluginName;
	this.currentDate = new Date();
	this.init();
  }
  // Avoid Plugin.prototype conflicts
  $.extend(Plugin.prototype, {
	init: function() {
	  var container = $(this.element);
	  var todayDate = this.currentDate;

	  var calendar = $('<div class="calendar"></div>');
	  var header = $('<header>' +
		'<h2 class="month"> </h2> ' +
		'&nbsp;<h2 class="year"> </h2>' +
		'</header>' +
		'<div class="d-flex controller-wrapper justify-content-end mb-3">' +
		'<a class="btn-prev me-3" href="#"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="icon" d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z" fill="#49454F"/></g></svg></a>' +
		'<a class="btn-next" href="#"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="icon" d="M12.6 12L8 7.4L9.4 6L15.4 12L9.4 18L8 16.6L12.6 12Z" fill="#49454F"/></g></svg></a>' + 
		'</div>');

	  this.updateYear(todayDate);

	  this.updateHeader(todayDate, header);
	  calendar.append(header);

	  this.buildCalendar(todayDate, calendar);
	  container.append(calendar);

	  this.bindEvents();
	},

	//Update the current month header
	updateHeader: function(date, header) {
	  header.find('.month').html(this.settings.months[date.getMonth()]);
	  header.find('.year').html(new Date().getFullYear());
	},

	//Update the current month header
	updateYear: function(date, header) {
	  //console.log(date.getFullYear());
	  $(this.settings.yearTargetID).html(date.getFullYear());
	  $('.calendar').find('.year').html(date.getFullYear());
	},

	//Build calendar of a month from date
	buildCalendar: function(fromDate, calendar) {
	  var plugin = this;

	  calendar.find('table').remove();

	  var body = $('<table></table>');
	  var thead = $('<thead></thead>');
	  var tbody = $('<tbody></tbody>');

	  //Header day in a week ( (1 to 8) % 7 to start the week by monday)
	  for (var i = 1; i <= this.settings.days.length; i++) {
		thead.append($('<td>' + this.settings.days[i % 7].substring(0, 2) + '</td>'));
	  }

	  //setting current year and month
	  var y = fromDate.getFullYear(),
		m = fromDate.getMonth();

	  //first day of the month
	  var firstDay = new Date(y, m, 1);
	  //If not monday set to previous monday
	  while (firstDay.getDay() != 1) {
		firstDay.setDate(firstDay.getDate() - 1);
	  }
	  //last day of the month
	  var lastDay = new Date(y, m + 1, 0);
	  //If not sunday set to next sunday
	  while (lastDay.getDay() != 0) {
		lastDay.setDate(lastDay.getDate() + 1);
	  }

	  //For firstDay to lastDay
	  for (var day = firstDay; day <= lastDay; day.setDate(day.getDate())) {
		var tr = $('<tr></tr>');
		//For each row
		for (var i = 0; i < 7; i++) {

		  var reqDay = y+'-'+(m+1)+'-'+day.getDate();
		  var dayEvent = false;
		  dayEvent = this.settings.events[reqDay];
		  //console.log('here is the event log');
		  //console.log(this.settings.events);
		  //console.log(this.settings.events[reqDay]);
		  if(dayEvent){
			var td = $('<td class="has-event" data-event="'+reqDay+'"><a href="#" class="day has-event">' + day.getDate() + '</a></td>');
		  } else {
			var td = $('<td><a href="#" class="day">' + day.getDate() + '</a></td>');
		  }

		  //if today is this day
		  if (day.toDateString() === (new Date).toDateString()) {
			td.attr('data-today', reqDay).find(".day").addClass("today").parent().addClass("todayDate");
		  }
		  //if day is not in this month
		  if (day.getMonth() != fromDate.getMonth()) {
			td.find(".day").addClass("wrong-month").parent().addClass("wrong-month");
		  }
		  //Binding day event
		  td.on('click', function(e) {
			e.preventDefault();
			plugin.triggerDetail($(plugin.element), $(this));
		  });

		  tr.append(td);
		  day.setDate(day.getDate() + 1);
		}
		tbody.append(tr);
	  }

	  body.append(thead);
	  body.append(tbody);

	  var eventContainer = $('<div class="event-container"></div>');

	  calendar.append(body);
	  calendar.append(eventContainer);
	},
	bindEvents: function() {
	  var plugin = this;

	  $('.btn-prev').click(function(e) {
		e.preventDefault();
		plugin.currentDate.setMonth(plugin.currentDate.getMonth() - 1);
		plugin.buildCalendar(plugin.currentDate, $('.calendar'));
		plugin.updateHeader(plugin.currentDate, $('.calendar header'));
		plugin.updateYear(plugin.currentDate);
		$('.day.has-event').click(function() { 
		  $('.applet.applet-datescalendar').addClass('d-block');
		  //$('.module.module-datescalendar').addClass('d-overflow');
		});
	  });

	  $('.btn-next').click(function(e) {
		e.preventDefault();
		plugin.currentDate.setMonth(plugin.currentDate.getMonth() + 1);
		plugin.buildCalendar(plugin.currentDate, $('.calendar'));
		plugin.updateHeader(plugin.currentDate, $('.calendar header'));
		plugin.updateYear(plugin.currentDate);
		$('.day.has-event').click(function() { 
		  $('.applet.applet-datescalendar').addClass('d-block');
		  //$('.module.module-datescalendar').addClass('d-overflow');
		});
	  });
	},
	triggerDetail: function(plugin, elem) {
	  var dayEvent = this.settings.events[elem.data('event')];
		$(this.settings.eventTargetID).find('.display-day').text(dayEvent.realday , dayEvent.realdate);
		$(this.settings.eventTargetID).find('.display-date').text(dayEvent.prettydate);
		$(this.settings.eventTargetID).find('.display-type').html(dayEvent.title);
		$(this.settings.eventTargetID).find('.display-starttime').text(dayEvent.starttime);
		$(this.settings.eventTargetID).find('.display-endtime').text(dayEvent.endtime);
		$(this.settings.eventTargetID).find('.display-place').text(dayEvent.where);
		$(this.settings.eventTargetID).find('.display-description').text(dayEvent.description);

	}
  });

  // A really lightweight plugin wrapper around the constructor,
  // preventing against multiple instantiations
  $.fn[pluginName] = function(options) {
	return this.each(function() {
	  if (!$.data(this, "plugin_" + pluginName)) {
		$.data(this, "plugin_" + pluginName, new Plugin(this, options));
	  }
	});
  };

})(jQuery, window, document);