// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
	$('.start-screen .hover-wrapper .hover-item:not(:first)').on('click', function () {
		$('#navbar-brand-container svg').addClass('opacity-on');
		$('.start-screen .hover-wrapper .hover-item.active').removeClass('active');
		$('.start-screen .hover-wrapper .hover-item.active').removeClass('special-case');
		$(this).addClass('active');
		$(this).addClass('special-case');
		var HoverItemInnerHeight = $(this).innerHeight();
		var HoverItemInnerEHeight = $(this).find('.logo-wrapper').outerHeight() + $(this).find('.text-wrapper').outerHeight();
		var HeightDiff = HoverItemInnerHeight - HoverItemInnerEHeight - 60;
		var NewHeight = $(this).find('.text-wrapper').outerHeight() + HeightDiff;
		//$(this).find('.text-wrapper').css('height' , NewHeight);
		//console.log(HeightDiff);
		//console.log(NewHeight);
	}); 


	$('.navbar-toggler').on('click', function () {
		$('#navbar-brand-container svg').addClass('opacity-on');
		$(this).toggleClass('active-toggler');
	}); 
	
	
	$('#nav-closer').on('click', function () {
		$('.navbar-toggler').click();
	});
	
	$('.single-services .ansprechpartner .nav-tabs button').first().click();
	
	setTimeout(function() { 
		$('.wsf-form button').append('<svg class="ms-2" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Group 2"><circle id="Ellipse 2" cx="10.2858" cy="10.2858" r="10.2858" fill="#FEFEFE"/><path id="Arrow 1" d="M14.6064 7.05322C14.6064 6.77708 14.3826 6.55322 14.1064 6.55322L9.60645 6.55322C9.3303 6.55322 9.10645 6.77708 9.10645 7.05322C9.10645 7.32937 9.3303 7.55322 9.60645 7.55322L13.6064 7.55322L13.6064 11.5532C13.6064 11.8294 13.8303 12.0532 14.1064 12.0532C14.3826 12.0532 14.6064 11.8294 14.6064 11.5532L14.6064 7.05322ZM6.69979 13.7528C6.50453 13.948 6.50453 14.2646 6.69979 14.4599C6.89506 14.6551 7.21164 14.6551 7.4069 14.4599L6.69979 13.7528ZM13.7529 6.69967L6.69979 13.7528L7.4069 14.4599L14.46 7.40678L13.7529 6.69967Z" fill="#009DD4"/></g></svg>');
	}, 500);
	
	window.onscroll = function () {
		//scrollRotate();
	};
	
	function scrollRotate() {
		$('.block_foruconsult-block_homeheader .col-lg-5 svg').css("transform", "rotate(" + window.pageYOffset/4 + "deg)"); 
	} 
	
	setTimeout(function() { 
		if($('body').hasClass('single-services')){
			var fServiceN = $('.single-services #foruconsult_service article').attr('id').substring(5);
			console.log(fServiceN);
			if($('#site-main').hasClass('content-blog-id-4')){
				$('form select#wsf-1-field-1').val(fServiceN).attr('selected','selected');    	
			}else if($('#site-main').hasClass('content-blog-id-2')){
				
				var eventID = new Array();
				$('.single-services #foruconsult_service article .block_foruconsult-block_eventrender .event-item').each(function() {
					eventID.push($( this ).attr('id').substring(6));
				});
				$('.single-services #foruconsult_service article form select#wsf-1-field-1 option').each(function() {
					var Events = $(this).val();
					if(jQuery.inArray(Events, eventID) != -1) {
						$(this).addClass('d-block');
					} else {
						$(this).addClass('d-none');
					} 
				});
				
				$('.single-services #foruconsult_service article form select#wsf-1-field-20 option').each(function() {
					var Events = $(this).val();
					if(jQuery.inArray(Events, eventID) != -1) {
						$(this).addClass('d-block');
					} else {
						$(this).addClass('d-none');
					} 
				});
				
				$('form select#wsf-1-field-2').val(fServiceN).attr('selected','selected');    	
			}
		}
		
		if($('body').hasClass('single-events')){
			var fServiceN = $('.single-events #foruconsult_service article').attr('id').substring(5);
			//console.log(fServiceN);
			var schulungscat = $('.single-events #foruconsult_service article .foruconsult-breadcrumbs #schulungs-cat').val();
			//console.log(schulungscat);
			$('.single-events #foruconsult_service article form select#wsf-1-field-2 option').each(function() {
				if($(this).val() == schulungscat){
					$(this).attr('selected','selected');		
				}	
			});
			if($('#site-main').hasClass('content-blog-id-2')){
				$('#wsf-1-field-20').append('<option value="placeholder">Wählen Sie bitte einen Termin</option>');
				$('.single-events #foruconsult_service article form select#wsf-1-field-1 option').each(function() {
					if($(this).val() == fServiceN){
						$(this).attr('selected','selected');		
						$(this).addClass('d-block').removeClass('d-none');
						
						
						var event_id = fServiceN; // Wert des gewählten Events
						// Stelle sicher, dass eine gültige Event-ID ausgewählt wurde
						//console.log(event_id);
						if (event_id) {
							$.ajax({
								url: ajaxurl, // WordPress AJAX URL
								type: 'POST',
								data: {
									action: 'load_event_data', // Unser AJAX-Action-Name
									event_id: event_id
								},
								success: function(response) {
									if (response.success) {
										var selectField = $('#wsf-1-field-20'); // Das Ziel-Select-Feld
										selectField.empty(); // Leere das Select-Feld
							
										// Füge eine leere Option hinzu
										selectField.append('<option value="placeholder">Wählen Sie bitte einen Termin</option>');
										//console.log(response.data);
										// Iteriere über die zurückgegebenen Optionen und füge sie dem Select hinzu
										$.each(response.data, function(index, option) {
											selectField.append('<option value="' + option.value + '">' + option.label + '</option>');
										});
									} else {
										alert(response.data.message); // Zeige Fehlermeldung an
									}
								},
								error: function() {
									alert('Fehler beim Laden der Daten.');
								}
							});
						}
						
					}else{
						$(this).removeClass('d-block').addClass('d-none');
					}
				});
				getDateEvent();
			}
		}
		
		if($('body').hasClass('single-jobs')){
			var fServiceN = $('.single-jobs #foruconsult_job article').attr('id').substring(5);
			//console.log(fServiceN);
			if($('#site-main').hasClass('content-blog-id-3')){
				$('form select#wsf-1-field-5').val(fServiceN).attr('selected','selected');    	
			}
		}
		
	}, 1000);
	
	
	
	
	
	
	function AppletCalendarSmall() {
		var ACScal = $('.applet.applet-datescalendar');
	
		if (ACScal.length) {
		  var ACSElms = ACScal.find('.display-calendar');
		  var ACSTabs = ACScal.find('.display-cat li');
		  var ACSLoader = $('.module-datescalendar').find('.js-ajax-loader');
	
		  function getACSeventsLoader() {
			ACSElms.hide();
			ACSLoader.show();
		  }
	
		  function loadFirstevent(){
			var target = 'spieltage';
	
			var targetCal        = ACScal.find('.display-calendar[data-cat="' + target + '"]');
			var targetCal_events = targetCal.find('td[data-event]:not(.wrong-month)');
			var now              = new Date();
			now.setHours(0,0,0,0);
			targetCal_events.each(function() {
			  this_event_date = new Date($(this).data('event'));
			  this_event_date.setHours(0,0,0,0);
			  if( this_event_date > now ){
				$(this).addClass('next-event');
				return false; 
			  }
			});
		  }
	
		  function getACSevents() {
			  
			  $.ajax({
				type: "GET",
				url: ajaxurl,
				dataType: "json",
				data: {
					action: 'get_events', // Unser AJAX-Action-Name
					predate: ACScal.data('realdate-preday')
				},
				beforeSend: getACSeventsLoader,
				success: function(response) {
				console.log(response);
				  $('.applet-datescalendar').find('.js-ajax-loader').hide();
				  $('.is-contentbrowser').find('.js-ajax-loader').hide();
				  $('#applDaCal_cal_spieltage').svwSCalendar({
					yearTargetID: '#applDaCal_year',
					eventTargetID: '#applDaCal_bar',
					events: response
				  }).show();
				  $('.day.has-event').click(function() { 
					//$('.module.module-datescalendar').addClass('d-overflow');
					$('.applet.applet-datescalendar').addClass('d-block');
				  });
				  
				  //loadFirstevent();
				},
				error: function(response){
				}
			  
			  });
		  }
		  
	
	
		  getACSevents($('#applDaCal_cal_spieltage').data('cat'));
		}
	  }
	  
	  setTimeout(function() { 
		  AppletCalendarSmall();
		  
		  $('#wsf-1-field-1').on('change', function() {
				var event_id = $(this).val(); // Wert des gewählten Events
				// Stelle sicher, dass eine gültige Event-ID ausgewählt wurde
				if (event_id) {
					$.ajax({
						url: ajaxurl, // WordPress AJAX URL
						type: 'POST',
						data: {
							action: 'load_event_data', // Unser AJAX-Action-Name
							event_id: event_id
						},
						success: function(response) {
							if (response.success) {
								var selectField = $('#wsf-1-field-20'); // Das Ziel-Select-Feld
								selectField.empty(); // Leere das Select-Feld
					
								// Füge eine leere Option hinzu
								selectField.append('<option value="placeholder">Wählen Sie bitte einen Termin</option>');
								console.log(response.data);
								// Iteriere über die zurückgegebenen Optionen und füge sie dem Select hinzu
								$.each(response.data, function(index, option) {
									selectField.append('<option value="' + option.value + '">' + option.label + '</option>');
								});
							} else {
								alert(response.data.message); // Zeige Fehlermeldung an
							}
						},
						error: function() {
							alert('Fehler beim Laden der Daten.');
						}
					});
				}
			});
		  
	  }, 1000);
	  
	  
	function getDateEvent() {
		$('#wsf-1-field-1').on('change', function() {
			var event_id = $(this).val(); // Wert des gewählten Events
			// Stelle sicher, dass eine gültige Event-ID ausgewählt wurde
			if (event_id) {
				$.ajax({
					url: ajaxurl, // WordPress AJAX URL
					type: 'POST',
					data: {
						action: 'load_event_data', // Unser AJAX-Action-Name
						event_id: event_id
					},
					success: function(response) {
						if (response.success) {
							var selectField = $('#wsf-1-field-20'); // Das Ziel-Select-Feld
							selectField.empty(); // Leere das Select-Feld
				
							// Füge eine leere Option hinzu
							selectField.append('<option value="placeholder">Wählen Sie bitte einen Termin</option>');
							console.log(response.data);
							// Iteriere über die zurückgegebenen Optionen und füge sie dem Select hinzu
							$.each(response.data, function(index, option) {
								selectField.append('<option value="' + option.value + '">' + option.label + '</option>');
							});
						} else {
							alert(response.data.message); // Zeige Fehlermeldung an
						}
					},
					error: function() {
						alert('Fehler beim Laden der Daten.');
					}
				});
			}
		});
	}

	
	//setTimeout(function() { 
		getDateEvent();  
	//}, 1000);
	
	var eventItems = $('.single-services .block_foruconsult-block_eventrender .event-items .event-item').length;
	if(eventItems === 0){
		$('.single-services .block_foruconsult-block_eventrender .event-items').removeClass('pb-3');
	}
	console.log(eventItems);
}); 